import { ChangeEvent, useState, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import ChakraCustomCheckbox from './ChakraCustomCheckbox';

export const MobileFilter = ({
  title,
  selectedItem,
  items,
  handleSelectedItem,
  isMultiple = false,
}: {
  title: string;
  selectedItem: string | string[];
  items: {
    label: string;
    value: string;
  }[];
  isMultiple?: boolean;
  handleSelectedItem: (value: string | string[]) => void;
}) => {
  const [filterValue, setFilterValue] = useState<string | string[]>('all' || []);

  useEffect(() => {
    if (selectedItem) {
      setFilterValue(selectedItem);
    }
  }, [selectedItem]);

  const handleApplyFilter = (value: string | string[]) => {
    handleSelectedItem(value);
  };

  const handleChecked = (
    filterValue: string | string[],
    item: { label: string; value: string },
  ) => {
    if (isMultiple) {
      return filterValue.includes(item.value);
    } else {
      return filterValue === item.value;
    }
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>, value: string) => {
    if (isMultiple) {
      let updatedList = Array.from(filterValue);
      if (e.target.checked) {
        updatedList = updatedList.concat(value);
      } else {
        updatedList.splice(filterValue.indexOf(value), 1);
      }
      setFilterValue(updatedList);
      handleSelectedItem(updatedList);
    } else {
      setFilterValue(value);
      handleSelectedItem(value);
    }
  };

  return (
    <Box mt="6">
      <Box fontWeight="bold">{title}</Box>
      {items.map((item) => (
        <ChakraCustomCheckbox
          key={item.label}
          w="100%"
          p="0.9375rem 0.75rem"
          isChecked={handleChecked(filterValue, item)}
          value={item.label}
          onChange={(e) => handleOnChange(e, item.value)}
          variant={isMultiple ? 'square' : 'circle'}
        >
          {item.label}
        </ChakraCustomCheckbox>
      ))}
    </Box>
  );
};
