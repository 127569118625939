import React, { useState, useEffect } from 'react';
import type { RefObject } from 'react';

import {
  Divider,
  Box,
  Flex,
  Text,
  Heading,
  Image,
  Tag,
  Wrap,
  WrapItem,
  VStack,
  HStack,
  Button,
  CheckCircleSolidIcon,
  BuildingIcon,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  ArrowRightIcon,
  BriefcaseIcon,
  Link,
} from '@terminal/design-system';
// Didn't want to add href to the links in this page to merge the next.js update quicker
// When we have time:
import { Link as ChakraLink } from '@chakra-ui/react';
import { useIsViewPortDesktop } from '../../../../global/utils/hooks';
import { debounce } from '../../../../global/utils/debounce';
import { CardWithViewMore } from '../../../../candindate/components/CardWithViewMore';
import {
  CommunicationModal,
  CommunicationModalBody,
  CommunicationModalFooterCTAs,
} from '../../../../candindate/components/CommunicationModal';
import type { JobDetailDataProps } from '../../../features/job/Job.types';
import noMatchImageSource from './assets/no-job-matches.svg';
import thankyouImageSource from './assets/job-thank-you.svg';

const JobSpecificationCard = ({
  title,
  description,
  isDesktop,
}: {
  title: string;
  description: string;
  isDesktop: boolean;
}) => {
  const [shouldShowFullDescription, setShouldShowFullDescription] = useState(false);
  return (
    <CardWithViewMore
      header={<Heading variant="heading-4">{title}</Heading>}
      description={description}
      onViewMoreClick={() => setShouldShowFullDescription(!shouldShowFullDescription)}
      isDesktop={isDesktop}
      shouldShowFullBody={shouldShowFullDescription}
    />
  );
};

export function JobDrawerDetail({
  applyCTAText = 'Apply',
  createCompanyHrefByID,
  createJobApplyHref,
  btnRef,
  doesHaveJobMatches,
  hasAlreadyApplied,
  isApplyButtonLoading,
  isOpen,
  jobDetailData,
  onApplyClick,
  onClose = () => {},
  onCompleteProfileClick,
  onAboutCompanyClick,
}: {
  applyCTAText?: string;
  createCompanyHrefByID: (id: number, companyName: string) => string;
  createJobApplyHref: () => string;
  btnRef?: RefObject<HTMLElement>;
  doesHaveJobMatches: boolean;
  hasAlreadyApplied: boolean;
  isApplyButtonLoading: boolean;
  isOpen: boolean;
  jobDetailData: JobDetailDataProps;
  onApplyClick?: () => void;
  onClose?: () => void;
  onCompleteProfileClick?: () => void;
  onAboutCompanyClick?: (organizationID: number) => void;
}) {
  const [viewportHeight, setViewportHeight] = useState(0);
  // const redirectTo = useRedirection();

  useEffect(() => {
    const onResize = debounce(() => {
      setViewportHeight(() => window.innerHeight);
    }, 250);
    setViewportHeight(() => window.innerHeight);

    document.addEventListener('resize', onResize);

    return () => {
      document.removeEventListener('resize', onResize);
    };
  }, []);

  if (!doesHaveJobMatches) {
    return (
      <Drawer isOpen={isOpen} placement="bottom" onClose={onClose} finalFocusRef={btnRef}>
        <DrawerOverlay />
        <DrawerContent px={3}>
          <DrawerCloseButton zIndex="sticky" left="auto" />
          <DrawerBody px={3} py={12}>
            <Flex
              flexDir="column"
              alignItems="center"
              h="80vh"
              minH="sm"
              justifyContent="center"
              bgColor={['transparent', 'transparent', 'ui.inverse.secondary']}
            >
              <Image src={noMatchImageSource.src} alt="No job suggestion match" maxW="145" />
              <Heading mt={6} variant="heading-2" color="text.primary">
                You Have No Matches Yet!
              </Heading>
              <Text mt={2} color="text.primary">
                Fill your profile to get matching jobs!
              </Text>

              <Button
                onClick={onCompleteProfileClick}
                mt={6}
                w={[350, 64]}
                position="absolute"
                bottom={6}
              >
                Complete Profile
              </Button>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    );
  }

  return (
    <Drawer isOpen={isOpen} placement="bottom" onClose={onClose} finalFocusRef={btnRef}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton zIndex="sticky" left="auto" />
        <DrawerBody px={3} py={0}>
          <Box
            pb={6}
            pt={14}
            height={viewportHeight ? `${viewportHeight - 80}px` : 'calc(100vh - 80px)'}
            maxW="full"
            overflowY="auto"
            overflowX="hidden"
          >
            {jobDetailData?.logo && (
              <Box w="full" mb={4}>
                <Image
                  w="full"
                  maxW="xs"
                  h={16}
                  src={jobDetailData?.logo.src}
                  alt={jobDetailData?.logo.alt}
                  style={{ objectFit: 'contain' }}
                  objectPosition="left"
                />
              </Box>
            )}
            {jobDetailData?.companyName && jobDetailData?.organizationID && (
              <Link
                href={createCompanyHrefByID(
                  jobDetailData.organizationID as number,
                  jobDetailData.companyName || '',
                )}
                color="accent.main"
                display="inline-block"
                onClick={() => onAboutCompanyClick?.(jobDetailData.organizationID as number)}
              >
                {jobDetailData?.companyName}
              </Link>
            )}
            {jobDetailData?.title && (
              <Heading color="text.primary" variant="heading-2" as="h1">
                {jobDetailData?.title}
              </Heading>
            )}
            <Divider borderColor="ui.secondary" my={4} opacity={1} />

            <Flex gridColumnGap={6} gridRowGap={3} flexWrap="wrap">
              {jobDetailData?.industry && (
                <Flex alignItems="center">
                  <BuildingIcon fontSize="2xl" color="ui.darker.disabled" />
                  <Flex flexDir="column" ml={3}>
                    <Text variant="hint" fontWeight="bold">
                      Industry
                    </Text>
                    <Text variant="caption">{jobDetailData?.industry}</Text>
                  </Flex>
                </Flex>
              )}

              {jobDetailData?.employmentType && (
                <Flex alignItems="center">
                  <BriefcaseIcon fontSize="2xl" color="ui.darker.disabled" />
                  <Flex flexDir="column" ml={3}>
                    <Text variant="hint" fontWeight="bold">
                      Job Type
                    </Text>
                    <Text variant="caption">{jobDetailData?.employmentType}</Text>
                  </Flex>
                </Flex>
              )}
            </Flex>

            {jobDetailData.organizationID && (
              <Button
                as={Link}
                href={createCompanyHrefByID(
                  jobDetailData.organizationID as number,
                  jobDetailData.companyName || '',
                )}
                fontWeight="bold"
                variant="ghost"
                color="accent.main"
                rightIcon={<ArrowRightIcon fontSize="xs" />}
                mt={2}
                sx={{
                  '&:hover': {
                    textDecoration: 'none',
                    color: 'accent.main',
                  },
                }}
              >
                About {jobDetailData?.companyName}
              </Button>
            )}

            <Divider borderColor="ui.secondary" my={4} opacity={1} />

            {jobDetailData?.techStacks && jobDetailData?.techStacks.length > 0 && (
              <>
                <Wrap mt={3} data-testid="tech-stack-wrapper">
                  {jobDetailData?.techStacks.map((techStack) => (
                    <WrapItem key={`techStack-${techStack}`}>
                      <Tag colorScheme="accent-lightest" size="sm" variant="subtle">
                        {techStack}
                      </Tag>
                    </WrapItem>
                  ))}
                </Wrap>
                <Divider borderColor="ui.secondary" my={4} opacity={1} />
              </>
            )}
            <Box flex="1" pb={6}>
              {(jobDetailData?.descriptiveInfos || []).map((info) => (
                <React.Fragment key={info.title}>
                  <JobSpecificationCard
                    title={info.title}
                    description={info.description}
                    isDesktop={false}
                    key={`job-info-${info.title}`}
                  />
                  <Divider
                    borderColor="ui.secondary"
                    my={6}
                    opacity={1}
                    _last={{ display: 'none ' }}
                  />
                </React.Fragment>
              ))}
              {jobDetailData?.tagInfos && jobDetailData?.tagInfos.length > 0 && (
                <VStack spacing={[3, 3, 6]} alignItems="start" data-testid="tag-infos-vstack">
                  {jobDetailData?.tagInfos.map((tagInfo) => (
                    <Box key={`tag-info-${tagInfo.title}`}>
                      <Text variant="caption" fontWeight="bold" color="accent.darkest">
                        {tagInfo.title}
                      </Text>
                      <Text mt={3}>{tagInfo.description}</Text>
                    </Box>
                  ))}
                </VStack>
              )}
              {jobDetailData?.tagInfos && jobDetailData?.tagInfos.length > 0 && (
                <VStack spacing={[3, 3, 6]} alignItems="start" data-testid="tag-infos-vstack">
                  {jobDetailData?.tagInfos.map((tagInfo) => (
                    <Box key={`tag-info-${tagInfo.title}`}>
                      <Text variant="caption" fontWeight="bold" color="accent.darkest">
                        {tagInfo.title}
                      </Text>
                      <Text mt={3}>{tagInfo.description}</Text>
                    </Box>
                  ))}
                </VStack>
              )}
            </Box>
            <Box position="sticky" w="full" bottom={0} zIndex="overlay" bg="bg.primary">
              <Button
                variant="primary"
                as={Link}
                sx={{
                  '&:hover': {
                    textDecoration: 'none',
                    color: 'text.inverse',
                  },
                }}
                href={createJobApplyHref()}
                onClick={onApplyClick}
                isLoading={isApplyButtonLoading}
                isDisabled={hasAlreadyApplied}
                leftIcon={hasAlreadyApplied ? <CheckCircleSolidIcon fontSize="md" /> : undefined}
                w="full"
              >
                {hasAlreadyApplied ? 'Applied' : applyCTAText}
              </Button>
            </Box>
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export function JobDetail({
  applyCTAText = 'Apply',
  createCompanyHrefByID,
  createJobApplyHref,
  focusDrawerBtnRef,
  hasAlreadyApplied,
  isApplyButtonLoading,
  isDrawerOpen = false,
  isModalOpen,
  jobDetailData,
  onApplyClick,
  onCompleteProfileClick,
  onDrawerClose,
  onModalClose = () => {},
  onAboutCompanyClick,
}: {
  applyCTAText?: string;
  createCompanyHrefByID: (id: number, companyName: string) => string;
  createJobApplyHref: () => string;
  focusDrawerBtnRef?: RefObject<HTMLElement>;
  hasAlreadyApplied: boolean;
  isApplyButtonLoading: boolean;
  isDrawerOpen?: boolean;
  isModalOpen: boolean;
  jobDetailData: JobDetailDataProps;
  onApplyClick?: () => void;
  onCompleteProfileClick?: () => void;
  onDrawerClose?: () => void;
  onModalClose?: () => void;
  onAboutCompanyClick?: (organizationID: number) => void;
}) {
  const isDesktop = useIsViewPortDesktop();

  return (
    <Box>
      {isDesktop && (
        <Box w="full" bg="ui.inverse.secondary">
          <Box p={8}>
            {jobDetailData?.logo && (
              <Box w="full" mb={6}>
                <Image
                  w="full"
                  maxW="xs"
                  h={16}
                  src={jobDetailData?.logo.src}
                  alt={jobDetailData?.logo.alt}
                  style={{ objectFit: 'contain' }}
                  objectPosition="left"
                />
              </Box>
            )}
            <HStack justifyContent="space-between" w="full" m={0}>
              <Box>
                {jobDetailData?.companyName && jobDetailData.organizationID && (
                  <Link
                    href={createCompanyHrefByID(
                      jobDetailData.organizationID as number,
                      jobDetailData.companyName || '',
                    )}
                    color="accent.main"
                    display="inline-block"
                  >
                    {jobDetailData?.companyName}
                  </Link>
                )}
                {jobDetailData?.title && (
                  <Heading color="text.primary" variant="heading-2" as="h1">
                    {jobDetailData?.title}
                  </Heading>
                )}
              </Box>
              <Button
                as={Link}
                href={createJobApplyHref()}
                onClick={onApplyClick}
                isLoading={isApplyButtonLoading}
                isDisabled={hasAlreadyApplied}
                leftIcon={hasAlreadyApplied ? <CheckCircleSolidIcon fontSize="md" /> : undefined}
                sx={{
                  '&:hover': {
                    textDecoration: 'none',
                    color: 'text.inverse',
                  },
                }}
              >
                {hasAlreadyApplied ? 'Applied' : applyCTAText}
              </Button>
            </HStack>
            <Divider borderColor="ui.secondary" my={6} opacity={1} />
            <Flex justifyContent="space-between">
              <Flex gridColumnGap={6} gridRowGap={3} flexWrap="wrap">
                {jobDetailData?.industry && (
                  <Flex alignItems="center">
                    <BuildingIcon fontSize="2xl" color="ui.darker.disabled" />
                    <Flex flexDir="column" ml={3}>
                      <Text variant="hint" fontWeight="bold">
                        Industry
                      </Text>
                      <Text variant="caption">{jobDetailData?.industry}</Text>
                    </Flex>
                  </Flex>
                )}

                {jobDetailData?.employmentType && (
                  <Flex alignItems="center">
                    <BriefcaseIcon fontSize="2xl" color="ui.darker.disabled" />
                    <Flex flexDir="column" ml={3}>
                      <Text variant="hint" fontWeight="bold">
                        Job Type
                      </Text>
                      <Text variant="caption">{jobDetailData?.employmentType}</Text>
                    </Flex>
                  </Flex>
                )}
              </Flex>
              {jobDetailData?.organizationID && (
                <Button
                  as={Link}
                  href={createCompanyHrefByID(
                    jobDetailData.organizationID as number,
                    jobDetailData.companyName || '',
                  )}
                  fontWeight="bold"
                  variant="ghost"
                  color="accent.main"
                  rightIcon={<ArrowRightIcon fontSize="xs" />}
                >
                  About {jobDetailData?.companyName}
                </Button>
              )}
            </Flex>
            {jobDetailData?.techStacks && jobDetailData?.techStacks.length > 0 && (
              <>
                <Wrap mt={3} data-testid="tech-stack-wrapper">
                  {jobDetailData?.techStacks.map((techStack) => (
                    <WrapItem key={`techStack-${techStack}`}>
                      <Tag colorScheme="accent-lightest" size="sm" variant="subtle">
                        {techStack}
                      </Tag>
                    </WrapItem>
                  ))}
                </Wrap>
                <Divider borderColor="ui.secondary" my={6} opacity={1} />
              </>
            )}
            <Box flex="1">
              {(jobDetailData?.descriptiveInfos || []).map((info) => (
                <React.Fragment key={info.title}>
                  <JobSpecificationCard
                    title={info.title}
                    description={info.description}
                    isDesktop={!!isDesktop}
                    key={`job-info-${info.title}`}
                  />
                  <Divider
                    borderColor="ui.secondary"
                    my={6}
                    opacity={1}
                    _last={{ display: 'none ' }}
                  />
                </React.Fragment>
              ))}
              {jobDetailData?.tagInfos && jobDetailData?.tagInfos.length > 0 && (
                <VStack spacing={[3, 3, 6]} alignItems="start" data-testid="tag-infos-vstack">
                  {jobDetailData?.tagInfos.map((tagInfo) => (
                    <Box key={`tag-info-${tagInfo.title}`}>
                      <Text variant="caption" fontWeight="bold" color="accent.darkest">
                        {tagInfo.title}
                      </Text>
                      <Text mt={3}>{tagInfo.description}</Text>
                    </Box>
                  ))}
                </VStack>
              )}
              {jobDetailData?.tagInfos && jobDetailData?.tagInfos.length > 0 && (
                <VStack spacing={[3, 3, 6]} alignItems="start" data-testid="tag-infos-vstack">
                  {jobDetailData?.tagInfos.map((tagInfo) => (
                    <Box key={`tag-info-${tagInfo.title}`}>
                      <Text variant="caption" fontWeight="bold" color="accent.darkest">
                        {tagInfo.title}
                      </Text>
                      <Text mt={3}>{tagInfo.description}</Text>
                    </Box>
                  ))}
                </VStack>
              )}
            </Box>
          </Box>
        </Box>
      )}
      {!isDesktop && (
        <JobDrawerDetail
          createCompanyHrefByID={createCompanyHrefByID}
          createJobApplyHref={createJobApplyHref}
          isOpen={isDrawerOpen}
          onClose={onDrawerClose}
          btnRef={focusDrawerBtnRef}
          onApplyClick={onApplyClick}
          isApplyButtonLoading={isApplyButtonLoading}
          hasAlreadyApplied={hasAlreadyApplied}
          doesHaveJobMatches={
            !!(jobDetailData?.title && jobDetailData?.companyName && jobDetailData?.techStacks)
          }
          onCompleteProfileClick={onCompleteProfileClick}
          applyCTAText={applyCTAText}
          jobDetailData={jobDetailData}
          onAboutCompanyClick={onAboutCompanyClick}
        />
      )}

      <CommunicationModal
        isOpen={isModalOpen}
        image={{ src: thankyouImageSource.src, alt: 'you have applied image' }}
        onClose={onModalClose}
      >
        <CommunicationModalBody>
          <Heading variant="heading-2" color="brand.main" textAlign="center">
            You Applied!
          </Heading>
          <Text mt={2} textAlign="center">
            You applied to {jobDetailData?.title} opportunity
            {jobDetailData?.companyName && ` at ${jobDetailData?.companyName}`}. Good luck!
          </Text>
        </CommunicationModalBody>
        <CommunicationModalFooterCTAs
          primaryCTA={{
            title: 'Go Back',
            onClick: onModalClose,
          }}
        />
      </CommunicationModal>
    </Box>
  );
}
