import { Container, Box, Flex, Heading, Text, Button } from '@terminal/design-system';

export const NoJobMatches = ({ resetFilters }: { resetFilters: () => void }) => {
  return (
    <Container w="100%">
      <Flex alignItems="center" justifyContent="center">
        <Box mt={{ base: '5.75rem', md: '8.25rem' }} w={{ base: '100%', md: '25rem' }}>
          <Box mx="auto" w="9.0625rem">
            <svg
              width="145"
              height="145"
              viewBox="0 0 145 145"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_722_32752)">
                <rect
                  x="0.75"
                  y="0.75"
                  width="143.5"
                  height="42.5"
                  rx="3.25"
                  fill="white"
                  stroke="#25383F"
                  strokeWidth="1.5"
                />
                <rect
                  x="0.75"
                  y="51.75"
                  width="143.5"
                  height="41.5"
                  rx="3.25"
                  stroke="#ABB6BA"
                  strokeWidth="1.5"
                  strokeLinecap="square"
                  strokeDasharray="10 12"
                />
                <rect
                  x="0.75"
                  y="101.75"
                  width="143.5"
                  height="42.5"
                  rx="3.25"
                  fill="white"
                  stroke="#25383F"
                  strokeWidth="1.5"
                />
                <rect x="9" y="8" width="27" height="28" rx="7.33333" fill="#BFD897" />
                <rect x="9" y="59" width="27" height="27" rx="7.33333" fill="#D9E3E7" />
                <rect x="9" y="109" width="27" height="28" rx="7.33333" fill="#AACED9" />
                <path
                  d="M28 18L32 22L28 26"
                  stroke="#7AA550"
                  strokeWidth="2"
                  strokeLinejoin="round"
                />
                <path
                  d="M17 18L13 22L17 26"
                  stroke="#7AA550"
                  strokeWidth="2"
                  strokeLinejoin="round"
                />
                <path d="M25 16L20 28" stroke="#7AA550" strokeWidth="2" strokeLinejoin="round" />
                <line
                  x1="45"
                  y1="12"
                  x2="96"
                  y2="12"
                  stroke="#8398A0"
                  strokeWidth="4"
                  strokeLinecap="round"
                />
                <line
                  x1="45"
                  y1="62"
                  x2="96"
                  y2="62"
                  stroke="#8398A0"
                  strokeWidth="4"
                  strokeLinecap="round"
                />
                <line
                  x1="45"
                  y1="113"
                  x2="96"
                  y2="113"
                  stroke="#8398A0"
                  strokeWidth="4"
                  strokeLinecap="round"
                />
                <line
                  x1="44"
                  y1="21"
                  x2="132"
                  y2="21"
                  stroke="#D9E3E7"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <line
                  x1="44"
                  y1="71"
                  x2="132"
                  y2="71"
                  stroke="#D9E3E7"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <line
                  x1="44"
                  y1="122"
                  x2="132"
                  y2="122"
                  stroke="#D9E3E7"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <line
                  x1="44"
                  y1="29"
                  x2="132"
                  y2="29"
                  stroke="#D9E3E7"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <line
                  x1="44"
                  y1="80"
                  x2="132"
                  y2="80"
                  stroke="#D9E3E7"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <line
                  x1="44"
                  y1="130"
                  x2="132"
                  y2="130"
                  stroke="#D9E3E7"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M16 66L21 71L16 76"
                  stroke="#83989F"
                  strokeWidth="2"
                  strokeLinejoin="round"
                />
                <path d="M21 77H31" stroke="#83989F" strokeWidth="2" strokeLinejoin="round" />
                <path
                  d="M24 129H27.4809C27.795 129 28.0963 128.889 28.3184 128.691C28.5405 128.493 28.6653 128.225 28.6653 127.945V125.146L31 122.877L28.6653 120.767V118.055C28.6653 117.775 28.5405 117.507 28.3184 117.309C28.0963 117.111 27.795 117 27.4809 117H24"
                  stroke="#5E9CB0"
                  strokeWidth="2"
                  strokeLinejoin="round"
                />
                <path
                  d="M21 129H17.5191C17.3634 129 17.2092 128.974 17.0652 128.921C16.9213 128.868 16.7905 128.79 16.6804 128.692C16.5703 128.594 16.4831 128.477 16.4238 128.349C16.3644 128.221 16.3342 128.084 16.3347 127.945V125.146L14 122.877L16.3347 120.767V118.055C16.3342 117.916 16.3644 117.779 16.4238 117.651C16.4831 117.523 16.5703 117.406 16.6804 117.308C16.7905 117.21 16.9213 117.132 17.0652 117.079C17.2092 117.026 17.3634 117 17.5191 117H21"
                  stroke="#5E9CB0"
                  strokeWidth="2"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_722_32752">
                  <rect width="145" height="145" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Box>
          <Box mt="6" textAlign="center">
            <Heading as="h2" fontSize="1.5rem">
              No Jobs Match Your Search
            </Heading>
            <Text mt="2" fontSize="sm">
              Try changing your filters to see other jobs!
            </Text>
            <Button
              w={{ base: '100%', sm: '16rem' }}
              mt={{ base: '6.25rem', sm: '6' }}
              onClick={() => resetFilters()}
            >
              Reset Filters
            </Button>
          </Box>
        </Box>
      </Flex>
    </Container>
  );
};
