import {
  Box,
  VStack,
  Button,
  Heading,
  Text,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { trackGetStarted } from '../../lib/segment/trackGetStarted';

import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

import modalImage from '../../public/images/openings/modal-image.svg';

export default function OpeningsModal({ utmParams }: { utmParams: string }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSmallerThan768] = useMediaQuery('(max-width: 768px)');

  const router = useRouter();

  useEffect(() => {
    if (sessionStorage.selectedJobClickCount && sessionStorage.selectedJobClickCount === '3') {
      onOpen();
    }

    // desktop exit intent
    const mouseEvent = (e) => {
      const shouldShowExitIntent = !e.toElement && !e.relatedTarget && e.clientY < 10;

      if (
        shouldShowExitIntent &&
        (!sessionStorage.selectedJobClickCount || sessionStorage.selectedJobClickCount <= 3)
      ) {
        document.removeEventListener('mouseout', mouseEvent);
        onOpen();
      }
    };

    // mobile exit intent
    let scrollStart = window.scrollY;
    // const pageHeight = document.documentElement.scrollHeight;
    const pageHeight = document.body.scrollHeight;

    if (
      pageHeight > 0 &&
      isSmallerThan768 &&
      (!sessionStorage.selectedJobClickCount || sessionStorage.selectedJobClickCount <= 3)
    ) {
      // scroll down
      const intervalDown = setInterval(function () {
        // let scrollAmount = scrollStart - window.scrollY;
        let scrollAmount = window.scrollY - scrollStart;

        if (scrollAmount < 0) {
          scrollAmount = 0;
          scrollStart = window.scrollY;
        }

        const scrollPercent = scrollAmount / pageHeight;

        if (scrollPercent > 0.4) {
          clearInterval(intervalDown);

          // console.log('reached 50% down');

          // chain to scroll up
          const intervalUp = setInterval(function () {
            let scrollAmount = scrollStart - window.scrollY;

            if (scrollAmount < 0) {
              scrollAmount = 0;
              scrollStart = window.scrollY;
            }

            const scrollPercent = scrollAmount / pageHeight;

            if (scrollPercent > 0.05) {
              clearInterval(intervalUp);

              onOpen();
              // console.log('reached 10% up');
            }
            // console.log('tick up', scrollPercent);
          }, 1000);
        }

        // console.log('tick down', scrollPercent);
      }, 1000);
    }

    document.addEventListener('mouseout', mouseEvent);
  }, [onOpen, router, isSmallerThan768]);

  const handleUnlockClick = () => {
    sessionStorage.selectedJobClickCount = 4;
    trackGetStarted({
      type: 'engineers',
      context: 'Job Page Modal',
    });
    router.push(publicRuntimeConfig.signUpUrl + utmParams);
  };

  const handleClose = () => {
    sessionStorage.selectedJobClickCount = 4;
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="xl" isCentered closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent bgColor="white" overflow="hidden" maxW="35rem" borderRadius="0" mx="0.9375rem">
        <ModalCloseButton zIndex="1" color="ui.primary" />
        <ModalBody textAlign="center" p="0">
          <Box py="6" bgColor="bg.tertiary">
            <Image
              maxW={{ base: '70%', lg: '28rem' }}
              mx="auto"
              src={modalImage.src}
              alt="Software engineer looking for next opportunity"
            />
          </Box>
          <Box p="6">
            <Heading variant="heading-2" as="h2" color="brand.main" my="2">
              Looking For Your Next Opportunity?
            </Heading>
            <Text mb="8">
              Create an account to unlock your job matches with our exclusive network of high-impact
              startups today!
            </Text>
            <VStack spacing={3}>
              <Button w="100%" onClick={handleUnlockClick}>
                Unlock My Job Matches
              </Button>
              <Button
                w="100%"
                variant="outline"
                onClick={handleClose}
                color="ui.primary"
                borderColor="ui.primary"
              >
                Go Back To Browse Jobs
              </Button>
            </VStack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
