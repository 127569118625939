export function debounce(func: (...args: any[]) => any, wait: number) {
  let timeout: NodeJS.Timeout;

  return function debounced(...args: any[]) {
    // @ts-ignore: this doesn't have a type
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}
