import { chakra, Flex, Box, useCheckbox } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';

const ChakraCustomCheckbox = (props) => {
  const { state, getCheckboxProps, getInputProps, getLabelProps, htmlProps } = useCheckbox(props);

  const CheckedIcon = () => {
    if (props.variant === 'circle') {
      return <Box w="0.375rem" h="0.375rem" bgColor="white" borderRadius="50%" />;
    } else {
      return <CheckIcon color="white" w="0.625rem" />;
    }
  };

  return (
    <chakra.label
      display="flex"
      flexDirection="row"
      alignItems="center"
      px={3}
      py={1}
      cursor="pointer"
      _hover={{
        bgColor: 'accent.lightest',
      }}
      {...htmlProps}
    >
      <input {...getInputProps()} hidden />
      <Flex
        alignItems="center"
        justifyContent="center"
        border="1.5px solid"
        borderRadius={props.variant === 'circle' ? '50%' : '0.125rem'}
        borderColor={state.isChecked ? 'accent.main' : 'ui-primary'}
        bgColor={state.isChecked ? 'accent.main' : 'transparent'}
        w={4}
        h={4}
        overflow="hidden"
        {...getCheckboxProps()}
      >
        {state.isChecked && <CheckedIcon />}
      </Flex>
      <Box ml="2" color={state.isChecked ? 'accent.main' : 'text-primary'} {...getLabelProps()}>
        {props.children}
      </Box>
    </chakra.label>
  );
};

export default ChakraCustomCheckbox;
