export default function slugifySkills(str: string) {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();
  str = str
    .replace(/asp .net/g, 'asp.net') // spacial case
    .replace(/c#/g, 'c-sharp') // spacial case
    .replace(/c\/c\+\+/g, 'c-cpp') // spacial case
    .replace(/f#/g, 'f-sharp') // spacial case
    .replace(/[^a-z0-9 .-]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes
  return str;
}
