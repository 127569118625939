import gql from 'graphql-tag';

export const GET_JOBS = gql`
  query SelectAllJobsMarketing @cached(ttl: 600) {
    icims_job(
      where: {
        icims_initial_data_transfer: { is_current: { _eq: true } }
        icims_folder: { value: { _eq: "Approved" } }
        is_position: { _eq: false }
        icims_job_positions: {
          icims_folder: { value: { _eq: "Approved" } }
          is_position: { _eq: true }
        }
        icims_profile_job: { active_on_job_portal_at: { _is_null: false } }
      }
      order_by: { created_at: desc }
    ) {
      profile_id
      created_at
      jobtitle
      tech_stack
      degree_requirement
      years_experience
      family
      icims_company {
        numofemps
        organizations {
          id
          salesforce_customer {
            industry
            website
          }
          logo
          name
          about
        }
      }
      about
      what_youll_do
      what_you_bring
      icims_job_locations {
        icims_location {
          value
        }
      }
      job {
        employment_type
      }
    }
    skill(
      where: { skill_features: { feature: { name: { _eq: "candidate-autocomplete" } } } }
      order_by: { name: asc }
    ) {
      name
    }
  }
`;
