import { Container, Box, Grid, GridItem, Heading } from '@terminal/design-system';

import squaresLight from '@public/images/ui/squares-light.svg';

export const Hero = () => {
  return (
    <Box
      pt={{
        base: '8',
        lg: '24',
      }}
      pb={{
        base: '8',
        lg: '24',
      }}
      bgImage={{ lg: `url(${squaresLight.src})` }}
      bgRepeat="no-repeat"
      bgPosition={{
        lg: 'calc(50% + 350px) center',
      }}
      bgSize={{ lg: '41.875rem' }}
    >
      <Container>
        <Grid
          gridTemplateColumns={{
            lg: '1fr 1fr',
            xl: '5.5fr 4.5fr',
          }}
          gap={{ base: '10', xl: '24' }}
        >
          <GridItem display={{ lg: 'flex' }} alignItems="center">
            <Box>
              <Heading
                as="h1"
                fontSize={{
                  base: '1.75rem',
                  lg: '3rem',
                }}
                lineHeight={{ base: 'none' }}
              >
                Remote software developer jobs with{' '}
                <Box display="inline-block" as="span" color="brand.main" position="relative">
                  high-impact startups.
                </Box>
              </Heading>
            </Box>
          </GridItem>
        </Grid>
      </Container>
    </Box>
  );
};
