import { useRouter } from 'next/router';
import { Box, Container } from '@chakra-ui/react';
import Layout from '@components/Layout';
import { JobMatchesWrapper, AboutTerminal } from '@_pages/engineering/job-openings/components';
import type { SelectAllJobsMarketingQuery } from '@_pages/engineering/job-openings/data';
import {
  demystifyJobOpeningsRouteRaw,
  serializerForMarketingJobs,
} from '@_pages/engineering/job-openings/utils';
import Head from 'next/head';
import { initializeApollo } from '@apollo-client';
import { useEffect, useState } from 'react';
import { GET_JOBS } from '@graphql/SelectAllJobsMarketing';

export function JobOpenings404Page() {
  const apolloClient = initializeApollo();
  const [allJobsData, setAllJobsData] = useState(null);

  // TODO: if error, send error response

  useEffect(() => {
    async function fetchData() {
      const { data } = await apolloClient.query<SelectAllJobsMarketingQuery>({
        query: GET_JOBS,
        context: { clientName: 'hasura' },
        fetchPolicy: 'no-cache',
        // @ts-ignore TODO: should remove?  TODO: fix apollo types
        nextFetchPolicy: 'no-cache',
      });

      setAllJobsData(data);
    }

    void fetchData();
  }, [apolloClient]);

  const router = useRouter();

  if (!allJobsData) return null;

  const marketingJobs = serializerForMarketingJobs(allJobsData);

  const { skills, slugs, ...marketingQueryparams } = router.query;

  const { filter, jobID: selectedJobID } = demystifyJobOpeningsRouteRaw({
    skills,
    slugs,
    supportedRoles: marketingJobs.supportedRoles,
  });

  return (
    <>
      <Head>
        <title>404: This job opening could not be found</title>
        <meta name="robots" content="noindex, nofollow" />
      </Head>

      <Layout type="engineers" withCTA={false}>
        <JobMatchesWrapper
          allJobsData={allJobsData}
          selectedJobID={selectedJobID}
          currentFilters={filter}
          marketingQueryparams={marketingQueryparams}
          pageConfig={{ path: '/engineers/job-openings' }}
          hasError={{
            '404': true,
          }}
        />
        <Box bgColor="white">
          <Container>
            <AboutTerminal />
          </Container>
        </Box>
      </Layout>
    </>
  );
}
