import { extendTheme } from '@chakra-ui/react';
import { theme as defaultTheme } from '../global/design-system';

const ui = {
  error: {
    lightest: '#FEF0EC',
    lighter: '#FEC9BD',
    main: '#F6623A',
    darker: '#842813',
    darkest: '#3A0F03',
  },
  warning: {
    lightest: '#FEF8EB',
    lighter: '#FFECBD',
    main: '#F6AE2D',
    darker: '#9A4B1D',
    darkest: '#3A2903',
  },
  success: {
    lightest: '#F5F8F1',
    lighter: '#CEE9AF',
    main: '#96BA6E',
    darker: '#546D3C',
    darkest: '#2B361B',
  },
  info: {
    lightest: '#E7F0F5',
    lighter: '#BADFF4',
    main: '#84CAF1',
    darker: '#48A1D3',
    darkest: '#177FB9',
  },
};

export const colors = {
  brand: {
    lightest: '#E7F5D6',
    lighter: '#BFD897',
    main: '#7AA550',
    darker: '#638245',
    darkest: '#4C6138',
  },
  accent: {
    lightest: '#E8EFF2',
    lighter: '#8BBFD0',
    main: '#5E9CB0',
    darker: '#517E94',
    darkest: '#25383F',
  },
  ui: {
    primary: '#25383F',
    secondary: '#D5DADC',
    disabled: '#EEF0F1',
    error: ui.error.main,
    success: ui.success.main,
    warning: ui.warning.main,
    info: ui.info.main,
    inverse: {
      primary: '#FFFFFF',
      secondary: '#F4F7F9',
    },
    lightest: {
      error: ui.error.lightest,
      success: ui.success.lightest,
      warning: ui.warning.lightest,
    },
    lighter: {
      error: ui.error.lighter,
      success: ui.success.lighter,
      warning: ui.warning.lighter,
      info: ui.info.lighter,
    },
    darker: {
      error: ui.error.darker,
      success: ui.success.darker,
      warning: ui.warning.darker,
      disabled: '#AFAFAF', // remove?
    },
    darkest: {
      error: ui.error.darkest,
      success: ui.success.darkest,
      warning: ui.warning.darkest,
    },
  },
  bg: {
    primary: '#FFFFFF',
    secondary: '#F8F8F8',
    tertiary: '#E8EFF2',
  },
  text: {
    primary: '#25383F',
    secondary: '#637A83',
    disabled: '#AFAFAF',
    inverse: '#FFFFFF',
    error: '#F6623A',
    success: '#96BA6E',
    link: '#5E9CB0',
    warning: ui.warning.main,
  },
};

export const styles = {
  global: {
    html: {
      h: 'fill-available',
      bg: 'bg.secondary',
    },
    body: {
      display: 'flex',
      flexDirection: 'column',
      bg: 'bg.secondary',
      minH: '100vh',
      minHeight: 'fill-available',
    },
    '#root': {
      flex: 1,
      display: 'flex',
    },
  },
};

type Dict = Record<string, any>;
const candidateTempButtonTheme = {
  variants: {
    // Temporarily overwrite the solid theme of the base theme, because the candidate app uses
    // accent.darkest for the bgHover of the solid:primary button instead of
    // accent.darkest
    solid: (props: Dict) => {
      const { colorScheme } = props;
      type ButtonColorScheme = 'primary' | 'accent' | 'error';

      const validColorScheme: ButtonColorScheme = ['primary', 'accent', 'error'].includes(
        colorScheme,
      )
        ? colorScheme
        : 'primary';

      const colorMap: {
        [key in ButtonColorScheme]: {
          bg: string;
          hoverBg: string;
          activeBg: string;
        };
      } = {
        primary: {
          bg: 'brand.main',
          hoverBg: 'accent.darkest',
          activeBg: 'accent.darkest',
        },
        accent: {
          bg: 'accent.main',
          hoverBg: 'accent.darker',
          activeBg: 'accent.darker',
        },
        error: {
          bg: 'ui.error',
          hoverBg: 'ui.darker.error',
          activeBg: 'ui.darker.error',
        },
      };

      return {
        bg: colorMap[validColorScheme].bg,
        color: 'text.inverse',
        _hover: {
          bg: colorMap[validColorScheme].hoverBg,
          _disabled: {
            bg: 'ui.disabled',
            color: 'text.disabled',
          },
        },
        _active: {
          bg: colorMap[validColorScheme].activeBg,
        },
        _disabled: {
          bg: 'ui.disabled',
          color: 'text.disabled',
        },
      };
    },
    /**
     * @deprecated
     * Variant:Solid ColorSchema:Primary should be used instead after Gabi accepts this change
     * TODO: Write ticket number
     */
    primary: (props: Dict) => {
      const { colorScheme } = props;
      type ButtonColorScheme = 'primary' | 'error';

      const validColorScheme: ButtonColorScheme = ['primary', 'error'].includes(colorScheme)
        ? colorScheme
        : 'primary';

      const colorMap: {
        [key in ButtonColorScheme]: {
          bg: string;
          hoverBg: string;
          activeBg: string;
        };
      } = {
        primary: {
          bg: 'brand.main',
          hoverBg: 'accent.darkest',
          activeBg: 'accent.darkest',
        },
        error: {
          bg: 'ui.error',
          hoverBg: 'ui.darker.error',
          activeBg: 'ui.darker.error',
        },
      };

      return {
        bg: colorMap[validColorScheme].bg,
        color: 'text.inverse',
        _hover: {
          bg: colorMap[validColorScheme].hoverBg,
          _disabled: {
            bg: 'ui.disabled',
            color: 'text.disabled',
          },
        },
        _active: {
          bg: colorMap[validColorScheme].activeBg,
        },
        _disabled: {
          bg: 'ui.disabled',
          color: 'text.disabled',
        },
      };
    },
    /**
     * @deprecated
     * Variant:Solid ColorSchema:Primary should be used instead
     * TODO: Write ticket number
     */
    secondary: {
      color: 'text.primary',
      border: '1px',
      borderColor: 'text.primary',
      _hover: {
        bg: 'bg.tertiary',
        _disabled: {
          borderColor: 'ui.disabled',
          color: 'text.disabled',
        },
      },
      _disabled: {
        borderColor: 'ui.disabled',
        color: 'text.disabled',
      },
    },
  },
};

export const theme = extendTheme(
  {
    colors,
    components: {
      Button: candidateTempButtonTheme,
    },
    styles,
    fonts: {
      heading: 'var(--font-outfit), sans-serif',
      body: 'var(--font-outfit), sans-serif',
    },
  },
  defaultTheme,
);
