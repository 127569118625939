import DOMPurify from 'dompurify';

export function stripAllHTMLAttributes(htmlString: string) {
  /**
   * Replace HTML tags with sanitized versions
   * - Allow 'a' tags to keep href attribute (matches first of single or double quotes)
   * - Strip attributes from all other tags
   */
  return DOMPurify.sanitize(htmlString, {
    USE_PROFILES: {
      html: true,
    },
    FORBID_ATTR: ['style', 'class'],
  });
}
