// TODO: Convert the rest of isMobile = useBreakpointValue({ base: true, md: false }) to use this hook.

import { useBreakpointValue } from '@chakra-ui/media-query';

/**
 * Detects if the user is on desktop viewport the width of witch is greater than mobile and tablet viewport
 * @since On component mount it returns undefined, be cautious how you use this utility
 * @todo See if its possible to make this function return the correct return value on mount
 * @returns boolean
 */
export function useIsViewPortDesktop(): boolean | undefined {
  return useBreakpointValue({ base: false, md: true }, { ssr: true });
}
