import type { JobSuggestionProps } from '../../../../candindate/shared/modules/job/data/JobSuggestions.types';
import {
  Heading,
  Box,
  VStack,
  Text,
  Image,
  Button,
  Switch,
  Flex,
  Tag,
  HStack,
  Stack,
  Container,
} from '../../../../global/design-system';
import { useIsViewPortDesktop } from '../../../../global/utils/hooks';
import { Link } from '@terminal/design-system';

export const JobCard = ({
  href,
  jobSection,
  activeSuggestion,
  onJobDetailClick,
  isDesktop,
  jobSuggestionData,
  skills_maxLength = 2,
}: {
  href: string;
  jobSection: 'suggested-jobs' | 'other-jobs';
  activeSuggestion: JobSuggestionProps['id'];
  onJobDetailClick: (job: JobSuggestionProps) => void;
  isDesktop?: boolean;
  jobSuggestionData: JobSuggestionProps;
  skills_maxLength?: number;
}) => {
  // TODO [CAND-1289]: Handle empty values better
  const {
    id,
    image = { src: undefined, alt: undefined },
    skills = [],
    title,
    subtitle,
    // applied,
  } = jobSuggestionData;

  return (
    <Link
      key={`job-card-${id}`}
      href={href}
      aria-label={`${jobSection}-job-card`}
      _selected={{
        boxShadow: 'none',
      }}
      _focus={{
        boxShadow: 'none',
      }}
    >
      <Box
        h="auto"
        w="full"
        pos="relative"
        p={3}
        justifyContent="space-between"
        onClick={() => onJobDetailClick(jobSuggestionData)}
        borderRight="1px solid"
        borderLeft="1px solid"
        borderBottom="1px solid"
        borderColor="ui.inverse.secondary"
        backgroundColor={
          isDesktop && activeSuggestion === id ? 'ui.inverse.secondary' : 'transparent'
        }
        _first={{
          borderTop: ['1px solid', '1px solid', 'none'],
          borderTopColor: ['ui.inverse.secondary', 'ui.inverse.secondary', 'transparent'],
        }}
        _selected={{
          boxShadow: 'none',
        }}
        _focus={{
          boxShadow: 'none',
        }}
        _hover={{
          backgroundColor: 'ui.secondary',
          borderColor: 'ui.secondary',
        }}
      >
        <HStack alignItems="flex-start" spacing={[3, 3, 6]} w="full">
          <Flex alignItems="center" justify="center" alignSelf="stretch">
            <Image
              w="3.75rem"
              h="3.75rem"
              src={image.src || undefined}
              alt={image.alt || undefined}
              style={{ objectFit: 'contain' }}
              objectPosition="center"
            />
          </Flex>
          <Box flex={1} overflow="hidden" w="full">
            <Box textAlign="left">
              <Text variant="hint" noOfLines={1}>
                {title}
              </Text>
              <Heading as="p" variant="heading-4" noOfLines={1}>
                {subtitle}
              </Heading>
            </Box>
            <HStack mt={2} spacing={2}>
              {skills.slice(0, skills_maxLength).map((skill) => (
                <Tag
                  key={`job-suggestion-skill-${skill}`}
                  display="block"
                  colorScheme="accent-lightest"
                  noOfLines={1}
                  title={skill}
                  pos="relative"
                  flexShrink={1}
                  size="sm"
                  variant="subtle"
                >
                  {skill}
                </Tag>
              ))}
              {skills.length > skills_maxLength && (
                <Box flexShrink={0}>
                  <Text variant="caption">{`+${skills.length - skills_maxLength} More`}</Text>
                </Box>
              )}
            </HStack>
          </Box>
          {/* {applied && (
          <CheckCircleSolidIcon
            pos="absolute"
            top="50%"
            transform={{ translateY: '-50%' }}
            right={3}
            color="brand.main"
          />
        )} */}
        </HStack>
      </Box>
    </Link>
  );
};

export function JobMatchesList({
  createJobLinkHrefByID,
  jobSuggestions,
  otherJobs,
  onJobDetailClick,
  onMatchAlertsChange,
  showIsSubscribedOn,
  activeSuggestion,
  notJobMatchesButtonBgColor,
  handleNoJobMatchesClick,
  jobsCount,
}: {
  createJobLinkHrefByID: (id: number) => string;
  jobSuggestions?: JobSuggestionProps[];
  otherJobs: JobSuggestionProps[];
  onJobDetailClick: (job: JobSuggestionProps) => void;
  handleNoJobMatchesClick?: () => void;
  onMatchAlertsChange?: () => void;
  showIsSubscribedOn?: boolean;
  activeSuggestion: JobSuggestionProps['id'];
  notJobMatchesButtonBgColor?: string;
  jobsCount?: JSX.Element;
}) {
  const isDesktop = useIsViewPortDesktop();

  return (
    <Flex w="full" flexDir="column" h="full">
      {onMatchAlertsChange && (
        <Flex
          flexDir={['column', 'column', 'row']}
          alignItems={['flex-start', 'flex-start', 'center']}
          justifyContent="space-between"
        >
          {isDesktop ? (
            <HStack p={3} bgColor="accent.darkest" w="full">
              <Box flex="1">
                <Text variant="label" color="text.inverse">
                  Email Job Alerts
                </Text>
                <Text variant="caption" color="text.inverse">
                  Get weekly alerts for matching jobs.
                </Text>
              </Box>
              <Flex h="full" align="center" justify="center">
                <Switch
                  onChange={onMatchAlertsChange}
                  isChecked={showIsSubscribedOn}
                  aria-label="job-alerts-toggle"
                />
              </Flex>
            </HStack>
          ) : (
            <HStack w="full" mb={6}>
              <Switch mr={3} onChange={onMatchAlertsChange} isChecked={showIsSubscribedOn} />
              <Text variant="body">Get Weekly Job Match Alerts</Text>
            </HStack>
          )}
        </Flex>
      )}
      <VStack
        align="flex-start"
        flex="1"
        overflowY="auto"
        overflowX="hidden"
        spacing={0}
        paddingBottom={6}
      >
        {jobSuggestions && (
          <Box p={3} bgColor="accent.main" w="full">
            <Text variant="label" color="text.inverse">
              Recommended For You
            </Text>
          </Box>
        )}
        {jobSuggestions && !jobSuggestions?.length && (
          <Flex justifyContent="start" w="full">
            <Button
              alignItems="start"
              bgColor={notJobMatchesButtonBgColor}
              flexDirection="column"
              onClick={handleNoJobMatchesClick}
              paddingLeft={3}
              py={8}
              variant="unstyled"
              w="full"
            >
              <Heading variant="heading-4" as="p">
                No Job Matches
              </Heading>
            </Button>
          </Flex>
        )}
        {jobSuggestions?.map?.((job) => (
          <JobCard
            href={createJobLinkHrefByID(job.id)}
            key={`suggested-jobs-${job.id}`}
            jobSuggestionData={job}
            activeSuggestion={activeSuggestion}
            onJobDetailClick={onJobDetailClick}
            jobSection="suggested-jobs"
            isDesktop={isDesktop}
          />
        ))}
        <Box w="full">
          {/* <Text variant="label" p={3} bgColor="bg.secondary">
            {jobSuggestions ? 'Other Jobs' : 'All Jobs'}
          </Text> */}
          {jobsCount}
          {otherJobs.map((job) => (
            <JobCard
              href={createJobLinkHrefByID(job.id)}
              key={`other-jobs-${job.id}`}
              jobSuggestionData={job}
              jobSection="other-jobs"
              activeSuggestion={activeSuggestion}
              onJobDetailClick={onJobDetailClick}
              isDesktop={isDesktop}
            />
          ))}
        </Box>
      </VStack>
    </Flex>
  );
}

export function JobMatches({
  createJobLinkHrefByID,
  jobSuggestions,
  otherJobs,
  onJobDetailClick,
  onMatchAlertsChange,
  showIsSubscribedOn,
  activeSuggestion,
  notJobMatchesButtonBgColor,
  handleNoJobMatchesClick,
  header,
  jobSectionDetail,
  shouldNotWrapWithContainer = false,
  jobsCount,
}: {
  createJobLinkHrefByID: (id: number) => string;
  jobSuggestions?: JobSuggestionProps[];
  otherJobs: JobSuggestionProps[];
  onJobDetailClick: (job: JobSuggestionProps) => void;
  handleNoJobMatchesClick?: () => void;
  onMatchAlertsChange?: () => void;
  showIsSubscribedOn?: boolean;
  activeSuggestion: JobSuggestionProps['id'];
  notJobMatchesButtonBgColor?: string;
  jobSectionDetail?: React.ReactNode;
  header?: React.ReactNode;
  shouldNotWrapWithContainer?: boolean;
  jobsCount?: JSX.Element;
}) {
  const isDesktop = useIsViewPortDesktop();

  const layout = (
    <>
      {header}
      <Stack
        direction={['column', 'column', 'row']}
        align="flex-start"
        spacing={0}
        /* calc(100vh - NavHeight - ContainerPadding - HeadingHeight - HeadingMargin) */
        h={['auto', 'auto', 'calc(100vh - 161px)']}
        mt={[3, 3, 6]}
      >
        <Box
          h={jobSuggestions?.length === 0 && !isDesktop ? 'auto' : 'full'}
          w={['full', 'full', '410px']}
          minW={['full', 'full', '410px']}
        >
          <JobMatchesList
            createJobLinkHrefByID={createJobLinkHrefByID}
            notJobMatchesButtonBgColor={notJobMatchesButtonBgColor}
            handleNoJobMatchesClick={handleNoJobMatchesClick}
            onJobDetailClick={onJobDetailClick}
            onMatchAlertsChange={onMatchAlertsChange}
            showIsSubscribedOn={showIsSubscribedOn}
            jobSuggestions={jobSuggestions}
            otherJobs={otherJobs}
            activeSuggestion={activeSuggestion}
            jobsCount={jobsCount}
          />
        </Box>
        <Box flex="1" h="full" w={['full', 'full', 'auto']} overflowY="auto">
          {jobSectionDetail}
        </Box>
      </Stack>
    </>
  );

  return shouldNotWrapWithContainer ? (
    layout
  ) : (
    <Container flex="1" paddingTop={[4, 4, 6]}>
      {layout}
    </Container>
  );
}
