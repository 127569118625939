import { useRouter } from 'next/router';
import { Box, Container } from '@chakra-ui/react';
import Layout from '@components/Layout';
import { determineMetaRobots } from '@helpers/metaRobots';
import { JobMatchesWrapper, AboutTerminal } from '@_pages/engineering/job-openings/components';
import type { SelectAllJobsMarketingQuery } from '@_pages/engineering/job-openings/data';
import {
  createSEOData,
  demystifyJobOpeningsRouteRaw,
  serializerForMarketingJobs,
} from '@_pages/engineering/job-openings/utils';
import { NextSeo } from 'next-seo';
import getConfig from 'next/config';
import Head from 'next/head';

const { publicRuntimeConfig } = getConfig();

export function JobOpeningsPage({
  allJobsData,
  marketingJobs,
}: {
  allJobsData: SelectAllJobsMarketingQuery;
  marketingJobs: ReturnType<typeof serializerForMarketingJobs>;
}) {
  const router = useRouter();

  const { skills, slugs, ...marketingQueryparams } = router.query;

  const { filter, jobID: selectedJobID } = demystifyJobOpeningsRouteRaw({
    skills,
    slugs,
    supportedRoles: marketingJobs.supportedRoles,
  });

  const seo = createSEOData({
    allJobsData,
    selectedJobID,
    filter,
    siteEnv: publicRuntimeConfig.siteEnv,
    jobOpeningsPath: '/engineers/job-openings',
  });

  return (
    <>
      <Head>
        {seo.structuredData && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(seo.structuredData) }}
          />
        )}
      </Head>
      <NextSeo
        {...seo.meta}
        {...determineMetaRobots({ translated: false, locale: router.locale })}
        openGraph={seo.openGraph}
        twitter={seo.twitter}
      />
      <Layout type="engineers" withCTA={false}>
        <JobMatchesWrapper
          allJobsData={allJobsData}
          selectedJobID={selectedJobID}
          currentFilters={filter}
          marketingQueryparams={marketingQueryparams}
          pageConfig={{ path: '/engineers/job-openings' }}
        />
        <Box bgColor="white">
          <Container>
            <AboutTerminal />
          </Container>
        </Box>
      </Layout>
    </>
  );
}
