import { Box, Heading, Text, Link } from '@terminal/design-system';
import dotPattern from '@public/images/openings/dot-pattern.svg';

export const AboutTerminal = () => {
  return (
    <Box pb="14">
      <Box
        display={{ xl: 'flex' }}
        p={{
          base: '4rem 2.875rem',
          lg: '4rem 4.625rem',
          xl: '4rem 4.625rem 4rem 6.25rem',
        }}
        bgColor="#475f7a"
        position="relative"
      >
        <Box
          w="17rem"
          h="6.375rem"
          position="absolute"
          top="0"
          left="0.5rem"
          bgImage={`url(${dotPattern.src})`}
          backgroundSize="150px"
          backgroundRepeat="repeat-x"
        />
        <Heading
          as="h2"
          color="white"
          mb="1rem"
          mt={{ xl: '0.5rem' }}
          mr={{ xl: '7.75rem' }}
          fontSize={{ base: '1.625rem', xl: '2.25rem' }}
          lineHeight={{ base: '1.875rem', xl: '2.5rem' }}
        >
          Why Terminal?
        </Heading>
        <Box>
          <Text mb="4" color="white">
            At Terminal, we identify emerging tech hubs around the globe, and connect the top
            engineers with the most compelling companies. We provide complete operations and
            services to give companies all the benefits of a new office without any of the hassle.
            We are focused on building a diverse and inclusive workforce. Terminal is an Equal
            Opportunity Employer and considers applicants for employment without regard to race,
            colour, religion, sex, orientation, national origin, age, disability, genetics or any
            other basis forbidden under federal, provincial, or local law.
          </Text>
          <Text color="white">
            We are committed to integration and equal opportunity. Accommodation is available to all
            applicants upon request throughout our recruitment process. Please contact{' '}
            <Link
              href="mailto:hr@terminal.io"
              textDecoration="underline"
              textUnderlineOffset="0.125rem"
            >
              hr@terminal.io
            </Link>{' '}
            if you require accommodation. We will work with all applicants to accommodate their
            accessibility needs.
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
